import React from "react";
import { useState } from "react";
import "./Popup.css";
// import logo from "../../assets/logo.png";
import r0 from "../../Assets/Images/Celex.jpg";
function Popup() {
  const [isVisible, setIsVisible] = useState(true);

  return (
    isVisible && (
      <div className="popup">
        <span className="close-btn" onClick={() => setIsVisible(false)}>
          &times;
        </span>
        <img src={r0} alt="Logo" className="popup-logo" />
        {/* <h2>Popup Title</h2>
        <p>This is a simple popup with an image.</p> */}
      </div>
    )
  );
}

export default Popup;

