import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Sidebar from "../Sidebar/Sidebar";
import Popup from "../Popup/Popup";
import { SnippetsOutlined } from "@ant-design/icons";
import { updateAuth } from "../../Redux/AuthSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import toast from "react-hot-toast";
import r0 from "../../Assets/Images/R0.png";
import r1 from "../../Assets/Images/R1.png";
import r2 from "../../Assets/Images/R2.png";
import logo from "../../Assets/Images/logo.png";
import Fleg from "../../Assets/Header/Flag.png";
import GrapH1 from "../../Assets/Dasahboard/graph.png";
import Vol from "../../Assets/Dasahboard/Volume.png";
import Bat from "../../Assets/Dasahboard/bactch.png";
import GRAPH from "../../Assets/Dasahboard/Stats.png";
import Arrow from "../../Assets/Dasahboard/Icon.png";
import Eye from "../../Assets/Dasahboard/eye-slash.png";
import cardImage from "../../Assets/sidebar/gameimage.png";
import cardImage2 from "../../Assets/sidebar/gameimage2.png";
import IconA from "../../Assets/sidebar/icon1.png";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dash, setdataArray] = useState([]);
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const [currenttimer, setcurrenttimer] = useState(0);
  const [Days_here_booster, setDays_here_booster] = useState(0);
  const [Hours_here_booster, setHours_here_booster] = useState(0);
  const [Munits_here_booster, setMunits_here_booster] = useState(0);
  const [Seconds_booster, setSeconds_booster] = useState(0);
  const [liverate, setliverate] = useState("");
  const [withdrawalToken, setwithdrawalToken] = useState("");
  const [News, setNews] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      //console.log("responceDashboard", responce);

      setdataArray(responce);
      setliverate("1 CELEX = " + responce.USDinToken + " USDT");

      let WithTokenAmount = responce.WithTokenAmount;
      if (WithTokenAmount) {
        if (parseFloat(WithTokenAmount) > 0) {
          let WithTokenAmounttoUsd = (parseFloat(WithTokenAmount) * parseFloat(responce.USDinToken)).toFixed(4);
          setwithdrawalToken(WithTokenAmount + " CELEX = " + WithTokenAmounttoUsd + " USDT");
        } else {
          setwithdrawalToken("");
        }
      } else {
        setwithdrawalToken("");
      }

      let responcenews = await API.get("GetNews", {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responcenews = responcenews.data.data[0];
      // console.log("GetNews-->", responcenews);
      setNews(responcenews);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
        );
        navigate("/");
      }
    }
  };

  const GetCurrentCycleTime = async () => {
    try {
      let responce = await API?.get(`getCurrentCycleTime?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data;
      // console.log("currentCycleTime", responce);
      //localStorage.setItem("Timer2", responce);
      setcurrenttimer(responce);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
        );
        navigate("/");
      }
    }
  };

  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  };
  // Notification end
  useEffect(() => {
    Dashboard_API();
    getNotification();
    GetCurrentCycleTime();
  }, []);

  useEffect(() => {
    if (currenttimer) {
      setInterval(() => {
        timer2();
      }, 1000);
    }
  }, [currenttimer]);

  const Timer2 = currenttimer; //localStorage.getItem("Timer2");
  //console.log("Timer2", Timer2);
  const timer2 = async () => {
    try {
      var currentDateTime = new Date();
      //console.log("currentDateTime", currentDateTime);
      let resultInSeconds = currentDateTime.getTime() / 1000;
      let topupInSeconds = new Date(Timer2).getTime() / 1000;
      let Time_here = topupInSeconds - resultInSeconds;
      let TimeFinal = parseInt(Time_here);
      if (TimeFinal <= 0 || Timer2 === "") {
        setDays_here_booster(0);
        setHours_here_booster(0);
        setMunits_here_booster(0);
        setSeconds_booster(0);
      } else {
        let days = parseInt(TimeFinal / 86400);
        setDays_here_booster(days);
        TimeFinal = TimeFinal % 86400;
        let hours = parseInt(TimeFinal / 3600);
        setHours_here_booster(hours);
        TimeFinal %= 3600;
        let munites = parseInt(TimeFinal / 60);
        setMunits_here_booster(munites);
        TimeFinal %= 60;
        let second_here = parseInt(TimeFinal);
        setSeconds_booster(second_here);
      }
    } catch (e) {
      console.log("Error While Timer", e);
    }
  };

  let URL = window.location.origin;

  const CopyReferralLink = () => {
    const text = `${URL}/register?userid=${userId}`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const CopyWalletAddress = () => {
    const text = `https://bscscan.com/address/${dash.walletAddress}`;
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const getImageSrc = (rank) => {
    switch (rank) {
      case 1:
        return r1;
      case 2:
        return r2;
      default:
        return r0;
    }
  };

  const OnHandleWalletAddress = () => {
    window.open(`https://bscscan.com/address/${dash.walletAddress}`, "_blank");
  };

  const OnHandlePancakeSwap = () => {
    window.open(
      `https://pancakeswap.finance/swap?inputCurrency=0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0&outputCurrency=0x55d398326f99059fF775485246999027B3197955`,
      "_blank"
    );
  };

  const OnHandleActivationLink = () => {
    navigate("/Activate");
  };

  const OnHandleWithdrawalLink = () => {
    navigate("/Working_Withdrawal");
  };

  const OnHandleRocLink = () => {
    navigate("/ReleaseRoiIncome");
  };
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <>
        {parseFloat(dash.ustatus) > 0 ? (
          <Popup />
        ) : (
          <span></span>
        )}
      </>

      <div className="container-fluid NewDashboard">
        <div className="row">
          <div
            className={` ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
          >
            <Sidebar />
          </div>
          <div className="col-lg-2 px-0 order-2">
            <div className="sidebarLeft px-3 position-relative">
              <div className="leftisidelogo">
                <img className="h-100 w-100" src={logo} alt="" />
              </div>
              <ul className="itemssidebar">
                <li>
                  <Link className="Anker" to="">
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    Package
                  </Link>
                  {dash.CurrentPackage}
                </li>
                <li>
                  <Link className="Anker" to="">
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    ROC
                  </Link>{" "}
                  {dash.roipercent} %
                </li>
                <li>
                  <Link
                    className="Anker"
                    target="_blank"
                    to="https://pancakeswap.finance/?inputCurrency=0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
                  >
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    Swap
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Working_Withdrawal"
                    className="Anker w-100"
                    id="txtchangevalue"
                    value="Withdrawal"
                    onClick={() => OnHandleWithdrawalLink()}
                  >
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    Withdrawal
                  </Link>
                </li>
                <li>
                  <button
                    className="Anker"
                    id="txtchangevalue"
                    value="Activation"
                    onClick={() => OnHandleActivationLink()}
                  >
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    Activation
                  </button>
                </li>
                <li>
                  <Link className="Anker" to="">
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>{" "}
                    Available Balance
                  </Link>{" "}
                  {dash.Net_Balance}
                </li>
                <li>
                  <Link className="Anker" to="">
                    <div className="icon">
                      <img className="w-100 h-100" src={IconA} alt="" />
                    </div>
                    Total Withdrawal
                  </Link>
                  {dash.WithDrawal_Amount}
                </li>
                {/* <li><Link className="Anker" to=""><div className="icon"><img className="w-100 h-100" src={IconA} alt="" /></div> Reward Income</Link>{dash.Lifetimereward}</li> */}
              </ul>
              <Link
                to="https://celexgame.com/"
                target="_blank"
                className="bottomimage"
              >
                <img className="w-100 h-100" src={cardImage} alt="" />
              </Link>
              <Link
                to="https://celexarbitrage.com/"
                target="_blank"
                className="bottomimage"
              >
                <img className="w-100 h-100" src={cardImage2} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 px-0 order-lg-2 order-1">
            <div className="contentside">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <header className="header ps-0">
                    <div className="hdleft d-flex align-items-center">
                      <div className="namelogo ps-3">Celex World</div>
                    </div>
                    <div className="rightside">
                      <div className="dropdown dropdow1">
                        <Link
                          className="btn btnsecondary text-back"
                          to="/Notification"
                        >
                          🔔{" "}
                          <span className="btn headerbtn">
                            {" "}
                            {NotificationCount}{" "}
                          </span>
                        </Link>
                      </div>
                      <div className="dropdown dropdow2 d-none d-md-block">
                        <Link
                          className="btn btnsecondary dropdown-toggle"
                          to=""
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="text1">
                            <p className="mb-1">ID Number</p>
                            <p className="mb-0 fw-bold text-start">{userId} </p>
                          </span>
                          <span className="icon">
                            <div className="name">
                              <img
                                className="w-100 h-100"
                                src="https://metaswift.ai/assets/img/user_pic.png"
                                alt=""
                              />
                            </div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu p-2">
                          <li className="text-white walltadd">
                            {dash.btcaddress}
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown dropdow2 d-block d-md-none">
                        <Link
                          className="btn btnsecondary dropdown-toggle"
                          to=""
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="icon">
                            <div className="name">
                              <img
                                className="w-100 h-100"
                                src="https://metaswift.ai/assets/img/user_pic.png"
                                alt=""
                              />
                            </div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="#">
                              Id Number <span>{userId}</span>
                            </Link>
                          </li>
                          <li className="text-white walltadd">
                            {dash.btcaddress}
                          </li>
                        </ul>
                      </div>
                      <button className="btn headerbtn">Connect Wallet</button>
                      <button
                        className="btn border-0 togglebtn"
                        id="togglebtn"
                        onClick={toggleSidebar}
                      >
                        <i
                          className={`fas fa-bars text-white ${isSidebarOpen ? "" : "collapsed"
                            }`}
                        ></i>
                      </button>
                    </div>
                  </header>
                </div>
                <div className="col-12">
                  <div className="contentsidesec w-100 pt-3">
                    <div className="row mx-0 borderbottom pb-3">
                      <div className="col-lg-8 ps-lg-0">
                        <div className="row mx-0 g-4 mb-4">
                          <div className="col-lg-5 col-md-6">
                            <div className="compaytext">
                              <span>
                                <img className="w-100 h-100" src={Vol} alt="" />
                              </span>
                              Affiliate Link
                            </div>
                            <input
                              id="myInput1"
                              className="Ankerinput form-control border-0 shadow-0"
                              type="text"
                              name="myInput2"
                              readonly="readonly"
                              value={`${URL}/register?userid=${userId}`}
                            />
                            <br />
                            <button
                              onClick={CopyReferralLink}
                              style={{
                                MarginTop: "10px",
                                cursor: "pointer",
                              }}
                              className="btn btntop"
                            >
                              {" "}
                              <span className="me-2">
                                <SnippetsOutlined />
                              </span>
                              Copy Link
                            </button>
                          </div>
                          <div className="col-lg-3">
                            <div className="compaytext">
                              <span>
                                <img className="w-100 h-100" src={Bat} alt="" />
                              </span>
                              Grade
                            </div>
                            <p className="text-black">{dash.MyRankName}</p>
                            {/* <button className="btn btntop">Infinity Pool</button> */}
                          </div>
                          <div className="col-lg-3">
                            <div className="compaytext">
                              <span>
                                <img className="w-100 h-100" src={Bat} alt="" />
                              </span>
                              Withdrawal Token Value
                            </div>
                            <p className="text-black">{withdrawalToken}</p>
                            {/* <button className="btn btntop">Infinity Pool</button> */}
                          </div>
                          <div className="col-lg-4 col-md-6 ps-0 d-none">
                            <div className="compaytext">
                              <span>
                                <img
                                  className="w-100 h-100"
                                  src={GrapH1}
                                  alt=""
                                />
                              </span>
                              Wallt Address
                            </div>
                            <div className="HeadingTim ">{dash.btcaddress}</div>
                          </div>
                        </div>
                        <div className="row mx-0 mb-4 align-items-end">
                          <div className="col-lg-7">
                            <Link
                              to="https://web3.bitget.com/en/swap/bnb/0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0?isShowHint=false"
                              className="GraphSection"
                            >
                              <div className="card grapgcard-box">
                                <div className="card-body">
                                  <div className="heading">Live Rate</div>
                                  <div className="liveratetext">{liverate}</div>
                                </div>
                              </div>
                              <div className="grap">
                                <img
                                  className="w-100 h-100"
                                  src={GRAPH}
                                  alt=""
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="col-lg-5">
                            <div className="card leftsidecardpoint mb-3">
                              <div className="card-body">
                                <div className="card-heading">ROC Earning</div>
                                <div className="Progressection my-3">
                                  <div className="progress">
                                    <div
                                      className="progress-bar rounded-0"
                                      role="progressbar"
                                      aria-label="Segment one"
                                      style={{
                                        width: "30%",
                                        background: "#0E0637",
                                      }}
                                      aria-valuenow="15"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                    <div
                                      className="progress-bar rounded-0"
                                      role="progressbar"
                                      aria-label="Segment two"
                                      style={{
                                        width: "50%",
                                        background: "#DAF727",
                                      }}
                                      aria-valuenow="30"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                    <div
                                      className="progress-bar rounded-0"
                                      role="progressbar"
                                      aria-label="Segment three"
                                      style={{
                                        width: "20%",
                                        background: "#00CAB2",
                                      }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                                <div className="totlalPoint">
                                  <div className="leftside">
                                    <span className="bg1"></span>Total
                                  </div>
                                  <div className="pont">
                                    {dash.Roi_incomeTotal}
                                  </div>
                                </div>
                                <div className="totlalPoint">
                                  <div className="leftside">
                                    <span className="bg2"></span>Hold
                                  </div>
                                  <div className="pont">
                                    {dash.Roi_incomeHold}
                                  </div>
                                </div>
                                <div className="totlalPoint">
                                  <div className="leftside">
                                    <span className="bg3"></span>Released
                                  </div>
                                  <div className="pont">{dash.Roi_income}</div>
                                </div>
                              </div>
                            </div>
                            <div className="card bottomcard">
                              <div className="card-body">
                                <div className="Ankercard">
                                  <marquee
                                    className="newsmarpuee text-black"
                                    //width="40%"
                                    direction="up"
                                    height="170px"
                                    scrollamount="3"
                                  >
                                    {News.map((item, index) => (
                                      <h6
                                        key={index}
                                        className=""
                                        dangerouslySetInnerHTML={{
                                          __html: item.content,
                                        }}
                                      />
                                    ))}
                                  </marquee>
                                  <span>
                                    <img
                                      className="w-100 h-100"
                                      src={Arrow}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row mx-0">
                          <div className="col-12">
                            <div className="groupbutton mb-3">
                              <button className="btn btnG active">
                                Leg 1 <span>{dash.Leg1}</span>
                              </button>
                              <button className="btn btnG">
                                Leg 2 <span>{dash.Leg2}</span>
                              </button>
                              <button className="btn btnG textsecondary">
                                Active Crew <span>{dash.directlegcount}</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="card leftsidecard mb-3">
                              <div className="card-body">
                                <div className="header">
                                  <div className="left">Total Business</div>
                                  <div className="right">
                                    {dash.TeamBusiness}
                                  </div>
                                </div>
                                <div className="twocrow">
                                  <div className="crow">
                                    <div className="heading">My Volunteer</div>
                                    <div className="grouptext">
                                      <div className="lefttext">
                                        <p className="toptext">Active</p>
                                        <p className="bottomtext">
                                          {dash.TotalActiveReferral}
                                        </p>
                                      </div>
                                      <div className="lefttext">
                                        <p className="toptext">Total</p>
                                        <p className="bottomtext">
                                          {dash.TotalReferral}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="crow">
                                    <div className="heading">My Crew</div>
                                    <div className="grouptext">
                                      <div className="lefttext">
                                        <p className="toptext">Active</p>
                                        <p className="bottomtext">
                                          {dash.TotalActiveTeam}
                                        </p>
                                      </div>
                                      <div className="lefttext">
                                        <p className="toptext">Total</p>
                                        <p className="bottomtext">
                                          {dash.TotalTeam}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-12">
                            <div className="card samecard mb-3 w-100">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    <div className="heading">
                                      {dash.TotalIncome}
                                    </div>
                                    <p>Total Earning</p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-12">
                            <a href="/SalaryIncome">
                              <div className="card samecard mb-3 w-100">
                                <div className="card-body">
                                  <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                      <div className="heading">Salary</div>
                                      <p>{dash.SalaryIncome}</p>
                                    </div>
                                    <div className="col-2">
                                      <div className="eyeimage">
                                        <img
                                          className="w-100 h-100"
                                          src={Eye}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-12 d-none">
                            <div className="card samecard mb-3">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    <div className="heading">
                                      {dash.ClaimedInvestmentAmount}
                                    </div>
                                    <p>Clamied Capitalt</p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0 pt-3">
                      <div className="col-lg-12 ps-lg-0">
                        <div className="row mx-0">
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="/levelincome">
                              <div className="card samecard mb-3 w-100">
                                <div className="card-body">
                                  <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                      <div className="heading">
                                        {dash.LevelIncome}
                                      </div>
                                      <p>Grade Earning</p>
                                    </div>
                                    <div className="col-2">
                                      <div className="eyeimage">
                                        <img
                                          className="w-100 h-100"
                                          src={Eye}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="/Lifetimereward">
                              <div className="card samecard mb-3 w-100">
                                <div className="card-body">
                                  <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                      <div className="heading">
                                        {dash.Lifetimereward}
                                      </div>
                                      <p>Reward Income</p>
                                    </div>
                                    <div className="col-2">
                                      <div className="eyeimage">
                                        <img
                                          className="w-100 h-100"
                                          src={Eye}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="/ctoIncome">
                              <div className="card samecard mb-3 w-100">
                                <div className="card-body">
                                  <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                      <div className="heading">
                                        {dash.CTOIncome}
                                      </div>
                                      <p>Kingship Pool</p>
                                    </div>
                                    <div className="col-2">
                                      <div className="eyeimage">
                                        <img
                                          className="w-100 h-100"
                                          src={Eye}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="/royaltyIncome">
                              <div className="card samecard mb-3 w-100">
                                <div className="card-body">
                                  <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                      <div className="heading">
                                        {dash.CTOIncome2}
                                      </div>
                                      <p>INFINITY POOL </p>
                                    </div>
                                    <div className="col-2">
                                      <div className="eyeimage">
                                        <img
                                          className="w-100 h-100"
                                          src={Eye}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card samecard mb-3 w-100">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    <div className="heading">
                                      {dash.LegBusiness}
                                    </div>
                                    <p>Reward Leg Business</p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card samecard mb-3 w-100">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    <div className="heading">
                                      {dash.Other_LegBusiness}
                                    </div>
                                    <p>Reward Other Leg Business</p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card samecard mb-3 w-100">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    {/* <div className="heading">{dash.EarnPer} %</div> */}
                                    <div className="heading">
                                      {" "}
                                      {dash.EarnAmount}
                                    </div>

                                    <p>Earn</p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card samecard mb-3 w-100">
                              <div className="card-body">
                                <div className="row mx-0">
                                  <div className="col-10 ps-0">
                                    {/* <div className="heading">{dash.CapingPer} %</div> */}
                                    <div className="heading">
                                      {dash.MaxIncome}
                                    </div>
                                    <p>Total Limit </p>
                                  </div>
                                  <div className="col-2">
                                    <div className="eyeimage">
                                      <img
                                        className="w-100 h-100"
                                        src={Eye}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
